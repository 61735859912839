import { lazy } from 'react'
import { Navigate } from 'react-router-dom-v5-compat'
import { type RouteConfig } from '../../shared/routing/types'

export const routes: RouteConfig[] = [
  {
    path: '/vehicles',
    component: lazy(() =>
      import('~/vehicles/VehiclesRoutes').then((module) => ({
        default: module.VehiclesRoutes,
      }))
    ),
    protected: true,
  },
  {
    path: '/reservations',
    component: lazy(() =>
      import('~/vehicles/VehiclesRoutes').then((module) => ({
        default: module.VehiclesRoutes,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Reservations',
      group: 'Dispatch & Operations',
      order: 1,
      groupOrder: 3,
    },
  },
  {
    path: '/rates',
    component: lazy(() => import('~/rates/RateMenuPage/')),
    protected: true,
    strict: true,
    headerItem: {
      name: 'Rate Menu',
      order: 3,
    },
  },
  {
    path: '/quotes-and-bookings/*',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage/pages/').then((module) => ({
        default: module.QuotesPageRoutes,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
    headerItem: {
      name: 'Quotes & Bookings',
      group: 'Sales',
      order: 1,
      groupOrder: 1,
    },
  },
  {
    path: '/analytics',
    component: lazy(() =>
      import('~/AnalyticsPage').then((module) => ({
        default: module.AnalyticsPage,
      }))
    ),
    protected: true,
    strict: true,
    featureFlag: 'rolloutEmbeddedAnalytics',
    headerItem: {
      name: 'Analytics',
      order: 5,
    },
  },
  {
    path: '/contracts',
    component: lazy(() =>
      import('~/contracts').then((module) => ({
        default: module.Page,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
    featureFlag: 'rolloutContracts',
    headerItem: {
      name: 'Contracts',
      group: 'Sales',
      order: 2,
    },
  },
  {
    path: '/customers',
    component: lazy(() =>
      import('~/CustomersPage').then((module) => ({
        default: module.CustomersPage,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Customers',
      order: 4,
    },
  },
  {
    path: '/drivers',
    component: lazy(() =>
      import('~/drivers').then((module) => ({
        default: module.DriversPage,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Drivers',
      group: 'Dispatch & Operations',
      order: 2,
    },
  },
  {
    path: '/deployments',
    featureFlag: 'rolloutDeployments',
    component: lazy(() =>
      import('~/DeploymentsPage').then((module) => ({
        default: module.DeploymentsPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,

    headerItem: {
      name: 'Deployments',
      group: 'Dispatch & Operations',
      order: 3,
    },
  },
  {
    path: '/embed',
    component: lazy(() =>
      import('~/EmbeddedFormContainer').then((module) => ({
        default: module.EmbeddedFormContainer,
      }))
    ),
  },
  {
    path: '/organization-settings/dispatch',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.DispatchLocationsSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/payments',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.PaymentsSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/integration',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.QuoteIntegrationSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/qnb',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.QuotesSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/trip',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.TripSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/embedded-trip-planner',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.EmbeddedTripPlannerSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/plan',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.Plan,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/drivers',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.DriversSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/profile',
    component: lazy(() =>
      import('~/profile').then((module) => ({
        default: module.Page,
      }))
    ),
    protected: true,
    strict: true,
  },
  {
    path: '/vehicles/my-vehicles',
    headerItem: {
      name: 'My Vehicles',
      group: 'Dispatch & Operations',
      order: 1,
      groupOrder: 2,
    },
  },
  {
    path: '/',
    component: (props) => (
      <Navigate to="/quotes-and-bookings/quotes" replace {...props} />
    ),
    compat: true,
    exact: true,
  },
  {
    path: '/login',
    component: (props) => {
      return <Navigate to="/" replace {...props} />
    },
    compat: true,
    exact: true,
  },
]
