import { Stepper } from '@busie/ui-kit'
import { observer } from 'mobx-react'
import React, { FC } from 'react'

import { ETPAmenity } from '@busie/api'
import { BusieLocale } from '@busie/utils'
import { locale } from '@busie/utils'
import ProviderNameLabel from './components/ProviderNameLabel'
import FormBlock, { StepName } from './FormBlock'
import { getIcon } from './getIcon'
import store from './store'
import QuoteFormStyled from './styled/QuoteFormStyled'

interface Props {
  embedded?: boolean
  customersAuthToken?: string
  pathfinderAuthToken?: string
  tripsAuthToken?: string
  quotesAuthToken?: string
  rateServiceAuthToken?: string
  guestAuthToken?: string
  embeddedFormOrgId?: string
  stepList: StepName[]
  quoteId?: string
  useMetricSystem?: boolean
  locale?: locale
  displayedAmenities?: ETPAmenity[]
}

export const QuoteForm: FC<React.PropsWithChildren<Props>> = observer(
  ({
    customersAuthToken,
    pathfinderAuthToken,
    tripsAuthToken,
    quotesAuthToken,
    rateServiceAuthToken,
    guestAuthToken,
    stepList,
    embedded,
    embeddedFormOrgId,
    quoteId,
    locale = BusieLocale.EN_US,
    useMetricSystem = false,
    displayedAmenities = [],
  }) => {
    store.locale = locale
    store.useMetricSystem = useMetricSystem
    store.displayedAmenities = displayedAmenities
    const nav = store.pageNavigation

    nav.stepList = stepList

    React.useEffect(() => {
      return () => store.resetAll()
    }, [])

    return (
      <QuoteFormStyled embedded={embedded}>
        <Stepper step={nav.currentStep} getIcon={getIcon} stepList={stepList} />
        <FormBlock
          customersAuthToken={customersAuthToken || ''}
          pathfinderAuthToken={pathfinderAuthToken || ''}
          tripsAuthToken={tripsAuthToken || ''}
          quotesAuthToken={quotesAuthToken || ''}
          rateServiceAuthToken={rateServiceAuthToken || ''}
          guestAuthToken={guestAuthToken || ''}
          embedded={embedded}
          embeddedFormOrgId={embeddedFormOrgId}
          step={nav.currentStep}
          goNext={nav.goNext}
          goPrev={nav.goPrev}
          setCurrentStep={nav.setCurrentStep}
          setNextStep={(index) => nav.setNextStep(index)}
          stepList={stepList}
          quoteId={quoteId}
        />
        {embedded && <ProviderNameLabel />}
      </QuoteFormStyled>
    )
  }
)
