import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEn from './en/translation.json'
import translationEs from './es/translation.json'

// adding a comment to resolve merge conflict

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      'es-MX': {
        translation: translationEs,
      },
      es: {
        translation: translationEs,
      },
    },
    fallbackLng: 'en',
    load: 'languageOnly',
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'], // Added localStorage first
      lookupLocalStorage: 'lz_currentLanguage',
      caches: ['localStorage', 'cookie'],
    },
    ns: ['translation'], // List all namespaces
    defaultNS: 'translation', // Set the default namespace
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  })

export default i18n
