import { useAuth0 } from '@auth0/auth0-react'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { Fragment } from 'react'
import { useLocation } from 'react-router'

import {
  getBrandingLogo,
  isOrganizationSettingsAllowed,
  User,
} from '@busie/utils'
import Link from './HeaderLink'
import Logo from './Logo'
import ProfileMenu from './ProfileMenu'

import { FlexContainer } from '../FlexContainer'
import MobileNavigationMenu from './MobileNavigationMenu'
import { NavigationMenu } from './NavigationMenu'
import AppBarStyled from './styled/AppBarStyled'
import ToolbarStyled from './styled/ToolbarStyled'

export interface HeaderItem {
  name: string
  to: string
  children?: { name: string; path: string; order?: number }[]
}

interface Props {
  isCustomer?: boolean
  headerLinks?: HeaderItem[]
}

export const Header: React.FC<React.PropsWithChildren<Props>> = ({
  isCustomer,
  headerLinks = [],
}) => {
  const location = useLocation()
  const isDesktopSized = useMediaQuery('(min-width:1116px)')
  const { user, isAuthenticated, logout } = useAuth0<User>()
  const isSettingsAllowed = user && isOrganizationSettingsAllowed(user)
  const brandingLogo = getBrandingLogo(user)
  const currentPage = headerLinks.find((hl) =>
    hl.children?.length
      ? hl.children.some((c) => location.pathname.includes(c.path))
      : hl.to === location.pathname.slice(0, hl.to.length)
  )
  const currentPageName = currentPage?.name.replace(' & ', '&')

  if (isCustomer) {
    return null
  }

  return (
    <AppBarStyled position="static">
      <ToolbarStyled>
        {!isCustomer && isDesktopSized ? (
          <Fragment>
            <FlexContainer align="center">
              <Logo logoSrc={brandingLogo} />

              {headerLinks.map((item, i) => {
                return item.children && item.children.length ? (
                  <NavigationMenu
                    key={item.name}
                    items={item.children.map(({ path, name }) => ({
                      name,
                      to: path,
                      active: location.pathname.includes(path),
                    }))}
                    name={item.name}
                    active={item.children.some(({ path }) =>
                      location.pathname.includes(path)
                    )}
                  />
                ) : (
                  <Link
                    to={item.to}
                    exact={true}
                    className={
                      location.pathname.slice(0, item.to.length) === item.to
                        ? 'selected'
                        : ''
                    }
                    key={item.to}
                  >
                    {item.name}
                  </Link>
                )
              })}
            </FlexContainer>
            {isAuthenticated ? (
              <FlexContainer align="center">
                <ProfileMenu
                  logout={() =>
                    logout({ returnTo: window.location.origin + '/login' })
                  }
                  user={user}
                  isSettingsAllowed={!!isSettingsAllowed}
                />
              </FlexContainer>
            ) : (
              <Link to={'/login'} exact={true}>
                Login
              </Link>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <Logo />
            <span>{currentPageName}</span>
            <MobileNavigationMenu
              headerLinks={headerLinks}
              logout={() =>
                logout({ returnTo: window.location.origin + '/login' })
              }
            />
          </Fragment>
        )}
      </ToolbarStyled>
    </AppBarStyled>
  )
}
