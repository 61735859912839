import { Range } from 'react-date-range'
import { DayjsExt, dayjsExt } from './dayjs'

export type DateRange = [DayjsExt | null, DayjsExt | null]

type RangeSelectionValue = {
  selection: Range & { key: string }
}

export const dateRangeToRange = (dateRange: DateRange): Range => ({
  startDate: dateRange[0]?.toDate() || undefined,
  endDate: dateRange[1]?.toDate() || undefined,
})

export const rangeToDateRange = (range: Range): DateRange => [
  range.startDate ? dayjsExt(range.startDate) : null,
  range.endDate ? dayjsExt(range.endDate) : null,
]

export const normalizeRangeSelection = (
  value: RangeSelectionValue | string[] | DateRange | Range
): DateRange => {
  if (Array.isArray(value)) {
    if (value[0] instanceof Date || typeof value[0] === 'string') {
      return [dayjsExt(value[0]), dayjsExt(value[1])]
    }
    return value as DateRange
  }

  if ('selection' in value) {
    const { selection } = value
    return rangeToDateRange(selection)
  }

  return rangeToDateRange(value as Range)
}
