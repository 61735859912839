import { Route } from 'react-router'
import { CompatRoute } from 'react-router-dom-v5-compat'
import { RouteConfig } from './types'
import { LazyRoute } from './utils'

export const renderRoute = (route: RouteConfig): JSX.Element | null => {
  if (!route.component) return null

  const routeProps = {
    key: `${route.path}-${route.exact}-${route.strict}`,
    path: route.path,
    exact: route.exact,
    strict: route.strict,
    component: LazyRoute({ component: route.component }),
  }

  return route.compat ? (
    <CompatRoute {...routeProps} />
  ) : (
    <Route {...routeProps} />
  )
}
