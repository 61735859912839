import { Loading } from '@busie/ui-kit'
import { Suspense } from 'react'
import { RouteComponentProps } from 'react-router-dom'
/**
 * Wrapper to render lazy-loaded components with Suspense
 */
export const LazyRoute = ({
  component: Component,
}: {
  component: React.ComponentType<RouteComponentProps>
}): React.ComponentType<RouteComponentProps> => {
  return (props) => (
    <Suspense fallback={<LoadingComponent />}>
      <Component {...props} />
    </Suspense>
  )
}
const LoadingComponent = () => {
  return (
    <Loading
      height="100%"
      width="100%"
      direction={'column'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    />
  )
}
