interface Props {
  borderColor?: string
  backgroundColor?: string
  label?: string
}

const Point = (
  colors: Props = {
    borderColor: '#3B3B3B',
    backgroundColor: 'white',
    label: '',
  }
): string => {
  return `<svg height="25" width="25" xmlns="http://www.w3.org/2000/svg">>
  <circle cx="12.5" cy="12.5" r="10" stroke="white" stroke-width="3" fill="red" />
  <text x="12" y="17" font-size="12px" font-family="Arial" font-weight="normal" text-anchor="middle" fill="white">${colors.label}</text>
</svg>`
}

export default Point
