import { useAuth0 } from '@auth0/auth0-react'
import { SubscriptionType, User } from '@busie/utils'
import React, { createContext, useContext, useMemo } from 'react'
import { usePlan } from '../../queries'
import { useAuthTokenWithAudience } from '../hooks'

interface UserContextProps {
  customersAuthToken: string
  embeddedAnalyticsAuthToken: string
  isPremium: boolean
  user?: User | undefined
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

interface UserContextProviderProps {
  children: React.ReactNode
}

/**
 * UserContextProvider component provides user-related context to its children components.
 *
 * @param {UserContextProviderProps} props - The properties for the UserContextProvider component.
 * @param {React.ReactNode} props.children - The child components that will have access to the user context.
 *
 * @returns {JSX.Element} The UserContext.Provider component with the provided context value.
 *
 * @remarks
 * This component uses the following hooks:
 * - `useOrganizationData`: Retrieves organization-related data including `organizationId` and `customersAuthToken`.
 * - `usePremiumSubscription`: Determines if the organization has a premium subscription based on `organizationId`.
 * - `useEmbedsAuthToken`: Retrieves the authentication token for embedded analytics.
 *
 * The context value provided includes:
 * - `customersAuthToken`: The authentication token for customers.
 * - `isPremium`: A boolean indicating if the organization has a premium subscription.
 * - `embeddedAnalyticsAuthToken`: The authentication token for embedded analytics.
 */
const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}: UserContextProviderProps): JSX.Element => {
  const { organizationId, customersAuthToken, user } = useOrganizationData()

  const isPremium = usePremiumSubscription(organizationId)

  const embeddedAnalyticsAuthToken = useAuthTokenWithAudience('embeds')

  const contextValue = useMemo(
    () => ({
      customersAuthToken,
      isPremium,
      embeddedAnalyticsAuthToken,
      user,
    }),
    [customersAuthToken, isPremium, embeddedAnalyticsAuthToken, user]
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

const usePremiumSubscription = (organizationId?: string): boolean => {
  const { data } = usePlan(organizationId)
  const subscriptionType = data?.subscriptionType

  const isPremium =
    subscriptionType && subscriptionType !== SubscriptionType.FREEMIUM

  return !!isPremium
}

const useOrganizationData = (): {
  organizationId?: string
  user?: User
  customersAuthToken: string
} => {
  const customersAuthToken = useAuthTokenWithAudience('customers')
  const { user } = useAuth0<User>()

  const organizationId = user?.org_id
  return { organizationId, customersAuthToken, user }
}

export const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useAuthToken must be used within an UserContextProvider')
  }
  return context
}

export {
  useOrganizationData,
  usePremiumSubscription,
  UserContext,
  UserContextProvider,
}
